.Holiday-container {
    display: flex;

    .Holidays {
        flex: 4;
    }

    .Holiday-page {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .Holiday-page-container {

            display: flex;
            flex-direction: column;
            height: auto;
            row-gap: 20px;
            width: 100%;

            .Holiday-page-container-heading {

                span {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: rgb(147, 147, 147);
                }
            }

            .Holiday-info-detail-container {
                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 12px;
                border-radius: 9px;
                padding: 0.5rem 0rem;
              

                .Holiday-info-detail-student-container {
                    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                    border-radius: 9px;
                    display: flex;
                    flex-direction: column;
                   padding: 0.5rem 0rem;
                  

                 
                    height: auto;
                    width: 100%;
                    gap: 12px;


                    .Holiday-info-detail-student-container-subheading {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding-left: 1rem;

                    }

                    .Holiday-info-detail-student-container-textfield {

                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 12px;
                       

                        .Holiday-info-section {

                            width: 98%;
                            display: flex;

                            gap: 25px;
                        }

                    }
                }
            }

        }
    }

} 