.mentor-schedule-container {
    display: flex;

    .mentor-schedule {
        flex: 4;

        span {
            font-size: 1.5rem;
            color: rgb(0, 0, 0);
            font-weight: 500;
        }

        .mentor-schedules-table {
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: flex-start;
            background-color: red;
            padding: 0;
            align-items: center;
        }
    }

}


.modal-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .larger-modal {
        width: 400px; 
        max-height: 80vh; 
    }
      
    .modal-paper {
        background-color: white;
        border: 2px solid #000;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        padding: 20px;

        h2 {
            margin-bottom: 20px;
        }

        form {
            display: flex;
            flex-direction: column;

            .form-field {
                margin-bottom: 20px;
            }

            .submit-button {
                margin-top: 20px;
            }
        }
    }
}