$main-color:#1377C0;
.teachers-container
{
    display: flex;
    width: 100%;
   
   
    .teachers{
        flex: 4;
       
        .teachers-page{
            display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: "center";
        gap: 20px;
       
         height: auto;
       .teacher-detail-heading{
                display: flex;
                flex-direction: column;
                gap: 10px;
                span{
                    font-size: 1.5rem;
                    color: rgb(0, 0, 0);
                    font-weight: 500;
                }
                .teacher-detail-search{
                    display: flex;
                    gap: 10px;
                    
                    input{
                        flex: 1;
                        width: 140px;
                        height: 50px;
                        padding-left:4px ;
                         border-radius: 9px;
                         border: 1px solid gray;
                    }
                    .teacher-detail-search-btn{
                        flex: 1;
                        button{
                            height: 54px;
                            width: 150px;
                            background-color:$main-color;
                            color: white;
                        font-weight: 600;
                        font-size: 1rem;
                        
                            border-radius: 9px;
                            border: none;
                            text-decoration: none;
                        }
                    }
                }
            }
           
        .teacher-table
        {
            width: 100%;
        
           display: flex;
           justify-content:"flex-start";

            flex-direction:column;
          padding-left:10;
            margin-left: 0;
           
           
          
            .newButton{
            
              width: 100%;
                
               
               

            button{

                height: 54px;
                width: 150px;
                background-color:$main-color;
                color: white;
            font-weight: 600;
            font-size: 1rem;
                border-radius: 9px;
                border: none;
                cursor: pointer;
                
            }
        }
        }
           
        }
    }
}
.viewButton{
    .btn{
        // background-color: #009df1;
    cursor: pointer;
    height: 30px;
    width: 70px;
        border-radius: 9px;
        color: rgb(0, 0, 0);
        font-size:1rem;
    //     // color:white
    //   text-decoration: underline;
    //   text-decoration-color: #009df1;
        border: none;
        font-weight: 500;
        background-color: white;

    }
}
.newButtonToggle{
            
    width: 100%;
      display: flex;
      justify-content: flex-start;
    

  button{

      height: 54px;
      width: 150px;
      background-color:$main-color;
      color: white;
  font-weight: 600;
  font-size: 1rem;
      border-radius: 9px;
      border: none;
      cursor: pointer;
      
  }
}