.global-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .global-button:hover {
    background-color: #45a049; /* Darker Green */
  }
  
  .global-button.loading {
    cursor: not-allowed;
    opacity: 0.7;
  }
  