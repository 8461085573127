.certificate-container-2 {
    width: 100%;
}
.certificate-section {
    border: 20px solid #0C5280;
    padding: 25px;
    height: 600px;
    position: relative; 
}

.certificate-section:after {
    content: '';
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    /* background-image: url(../../assest/gaanvwala.png); */
    opacity: 0.2;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
}

.certificate-header > .logo {
    width: 80px;
    height: 80px;
}

.certificate-title {
    text-align: center;  
    font-size: 1.8em;  
}

.certificate-body {
    text-align: center;
}

h1 {

    font-weight: 400;
    font-size: 48px;
    color: #0C5280;
}

.student-name {
    font-size: 24px;
}

.certificate-content {  
    display: flex;
    justify-content: center;
}
 

.about-certificate {
    width: 380px;
    margin: 0 auto;
}

.topic-description {

    text-align: center;
}

.certificate-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date p{    
    font-size: 1.3em;
    font-weight: 600;
}

.signature p{    
    font-size: 1.3em;
    font-weight: 600;
}

.row{
    display: flex;
    width: 100%;
}

.input-data{
    text-decoration: underline;
    font-weight: 700;
    font-size: 1em;
}