$main-color: #1377C0;

.teachers-container {
      display: flex;

      .teachers {
            flex: 4;

            .teachers-page {
                  display: flex;
                  flex-direction: column;
                  gap: 20px;

                  .teacherForm-page-container {

                        display: flex;
                        flex-direction: column;
                        height: auto;
                        row-gap: 20px;
                        width: 100%;

                        .teacherForm-page-container-heading {

                              span {
                                    font-size: 1.5rem;
                                    font-weight: 600;
                                    color: rgb(147, 147, 147);
                              }
                        }

                        .teachers-info-detail-container {
                              width: 100%;

                              display: flex;
                              flex-direction: column;
                              gap: 12px;
                              border-radius: 9px;

                              .teachers-info-detail-student-container {
                                    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                                    border-radius: 9px;
                                    display: flex;
                                    flex-direction: column;
                                    padding-bottom: 20px;


                                    height: auto;
                                    width: 100%;
                                    gap: 12px;


                                    .teachers-info-detail-student-container-subheading {
                                          width: 100%;
                                          display: flex;
                                          align-items: center;
                                          padding-left: 1rem;

                                    }

                                    .teachers-info-detail-student-container-textfield {

                                          display: flex;
                                          flex-direction: column;
                                          align-items: center;
                                          justify-content: center;
                                          gap: 12px;

                                          .teachers-info-section {

                                                width: 98%;
                                                display: flex;

                                                gap: 25px;
                                          }

                                    }


                              }

                              //      .teachers-info-detail-parent-container{
                              //       box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                              //       border-radius: 9px;
                              //       display: flex;
                              //      flex-direction: column;
                              //      height: 50vh;
                              //      width: 100%;
                              //      gap: 20px;
                              // //      .student-info-detail-parent-container-subheading{
                              // //       margin-top: 10px;
                              // //       width: 100%;
                              // //       display: flex;
                              // //       align-items: center;
                              // //       padding-left: 1rem;
                              // //   }
                              // //   .student-info-detail-parent-container-textfield{
                              // //       display: flex;
                              // //       flex-direction: column;
                              // //       align-items: center;
                              // //       justify-content: center;
                              // //       gap: 12px;

                              // //       .parent-info-section{

                              // //             width: 98%;
                              // //             display: flex;

                              // //             gap: 25px;
                              // //       }

                              // //   }

                              //      }
                              //      .student-info-detail-fee-container{
                              //       box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                              //       border-radius: 9px;
                              //       display: flex;
                              //       flex-direction: column;
                              //       height: 40vh;
                              //       width: 100%;


                              //      }
                        }

                  }


            }
      }
}

.subheading {
      font-size: 1rem;
      font-weight: 500;
      color: rgb(56, 56, 56);
}

.buttonSubmit {

      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1rem;
      width: 100px;
      height: 50px;
      border-radius: 9px;
      color: white;
      background-color: $main-color;
      text-decoration: none;

}

.viewButton {
      margin-left: 10%;
}

.parent-teacher {
      width: 100%;

}