.add-subject-container{
    width: 100%;
    height: auto;
    display:flex;
    flex-direction: column;
    margin-top: 2rem;
    row-gap: 2rem;
   
   .add-subject-filter-container{
    display: flex;
    flex-direction:column;
    row-gap: 1rem;
    column-gap: 1rem;
    padding: 0.5rem;
   }
   .add-subject-table-container
   {
    flex:1;
    padding: 0.5rem;

   }
}