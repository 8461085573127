.dashboard-container
{
    display: flex;
   
    
    .dashboard{
        flex: 4;
        
       
      
        .dashboard-page{
        
          
            display: flex;
            flex-direction: column;
            gap: 20px;
           
            display: flex;
            justify-content:'flex-start';
            padding-top: 10px;
           
           
           
          
         
           
         
        }
    }
}