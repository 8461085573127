$main-color: #1377C0;

.ptm-report-container {
    display: flex;

    .ptm-report {
        flex: 4;
        width: 100%;

        .ptm-report-page {
            display: flex;
            flex-direction: column;
            gap: 20px;

            justify-content: center;
            // padding: 1rem 0 0 4rem ;



            .ptm-report-detail-heading {
                display: flex;
                flex-direction: column;
                gap: 10px;

                span {
                    font-size: 1.5rem;
                    color: black;
                    font-weight: 500;
                }
            }

        }
    }
}

.viewButtton {

    button {
        // background-color: #009df1;
        cursor: pointer;
        height: 30px;
        width: 70px;
        border-radius: 9px;
        color: rgb(0, 0, 0);
        font-size: 1rem;
        // color:white
        text-decoration: underline;
        //   text-decoration-color: #009df1;
        border: none;
        font-weight: 500;
        background-color: white;
    }

}