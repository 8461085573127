.calendar {
    width:100%;
    
  
    border: 1px solid #ccc;
    background-color: #ffffff;
  
    border-radius: 9px;
   padding: 20px;
    min-height: 300px;
    height: auto;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    
  }
  
  .monthYear {
    display: flex;
    column-gap: 20px;
    margin-bottom: 40px;
    
    
  }
  select{
    border: none;
    color: black;
    font-size: 20px;
    font-weight: bold;
  }
  .btn{
    border: none;
    color: black;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
  }
  
  .dayName {
    display: flex;
  }
  
  .row {
  
    display: grid;
grid-template-columns: repeat(7, 1fr);
grid-template-rows: repeat(1,1fr);
grid-column-gap: 5px;
grid-row-gap: 5px;
margin-bottom: 5px;


  }
  
  .cell { 
    width: 13%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 9px;
    color: rgb(0, 0, 0);
  }
  
  .date {
    font-weight: bold;
  }
  
  .day {
    margin-top: 4px;
  }
  
  .emptyCell { 
    width: 13%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc00;
    
    border-radius: 9px;
   
   
  }
  .squreBoxOfDay{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .cell-green{
    background-color: #afefaf;
    color: rgb(0, 0, 0);
  }
  .cell-future{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
  }
  .cell-red{
    background-color:#f3b9b9;
  }
  .cell-holiday{
    background-color: rgb(197, 192, 192);
  }
  .button-container{
    display: flex;
    column-gap: 5px;
    
  }
  .attedance-circularProgress{
    display: flex;
    height: 400px;
    width: 1300px;
    justify-content: center;
    align-items: center;
   
  }

  