$main-color: #1377C0;
$container-bottom-border: .2px solid rgb(227, 227, 227);
;

.SingleTeacherPage-container {
    display: flex;

    .SingleTeacher {
        flex: 4;

        .SingleTeacher-page {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;


            .student-info-main-container {

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 98%;
                row-gap: 20px;



                .student-info-heading {

                    border-radius: 9px;
                    width: 98%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;



                    padding-left: 1rem;

                    h1 {
                        font-size: 1.3rem;
                        font-weight: 700;
                        color: #929292;

                    }
                }

                .section {

                    // box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                    width: 98%;
                    height: 30vh;
                    flex: 1;
                    border-bottom: $container-bottom-border;


                }

                .basic-info {
                    width: 96.5%;
                    padding: 0.5rem;
                    border-radius: 0px;
                    //    border: 2px solid black;
                    display: flex;
                    flex-direction: row;
                  
                    flex: 1;

                    height: 40vh;
                    gap: 20px;


                    .basic-info-right {
                        flex: 3;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 20px;

                        .student-Name {
                            flex: 1;
                            padding-left: 15px;
                            display: flex;
                            justify-content: flex-start;

                            align-items: center;

                            span {

                                font-size: 1.3rem;
                                font-weight: 700;
                                color: rgb(93, 93, 93);

                            }

                        }

                        .other-info-container {
                            flex: 2;
                            display: flex; 
                            flex-direction: column;
                            align-items: flex-start;

                            .other-detail-info-container {

                                flex: 1;
                                display: flex;
                                width: 100%;
                                justify-content: space-evenly;
                                align-items: flex-start;

                                flex-direction: row; 

                                .student {
                                    display: flex;
                                    height: 6vh;
                                    width: 100%;
                                    gap: 12px; 

                                    .lable {
                                        font-size: 1rem;
                                        font-weight: 500;
                                        color: $main-color;
                                    }

                                    span {
                                        font-size: 1rem;
                                        color: rgb(83, 83, 83);
                                    }


                                }

                                //    .student{
                                //     display: flex;
                                //     // background-color: rgb(42, 231, 231);
                                //     height: 6vh;
                                //     gap:12px;
                                //     .lable{
                                //         font-size: .9rem;
                                //         font-weight: 500;
                                //         color: #1378c09a;
                                //     }

                                //    }
                                //    .student{
                                //     display: flex;
                                //     // background-color: rgb(25, 183, 183);
                                //     height: 6vh;
                                //     gap:12px;
                                //     .lable{
                                //         font-size: .9rem;
                                //         font-weight: 500;
                                //         color: #1378c09a;
                                //     }


                                //    }
                                //    .student{
                                //     display: flex;
                                //     // background-color: rgb(37, 203, 203);
                                //     height: 6vh;
                                //     gap:12px;
                                //     .lable{
                                //         font-size: .9rem;
                                //         font-weight: 500;
                                //         color: #1378c09a;
                                //     }

                                //    }
                            }
                        }
                    }

                }

                .student-salary-container {
                    // border: 2px solid black;
                    // box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                    width: 98%;
                    height: auto;
                    border-radius: 9px;
                    padding-bottom: 20px;
                    padding-top: 10px;
                    padding-left: 5px;
                    padding-right: 5px;






                    .student-salary-container-heading {

                        border-radius: 9px;
                        width: 98%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;



                        padding-left: 1rem;

                        h1 {
                            font-size: 1.3rem;
                            font-weight: 700;
                            color: #929292;

                        }
                    }

                    .student-info-container-body {
                        height: auto;

                        display: flex;
                        flex-direction: column;
                        row-gap: 20px;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;

                    }

                    .btn {
                        display: flex;

                        justify-content: flex-end;

                        button {
                            height: 54px;
                            width: 150px;
                            background-color: $main-color;
                            color: white;
                            font-weight: 600;
                            font-size: 1rem;
                            border-radius: 9px;
                            text-decoration: none;
                            border: none;
                        }
                    }

                }

            }



        }
    }
}