.cancelButton{
    height: 50px;
    width:100px;
    background-color: red;
    text-decoration: none;
  color:white;
  font-size: 15;
  font-weight: bold;
}
.approveButton{
    height: 50px;
    width: 100px;
    background-color: rgb(68, 246, 68);
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 15;
    font-weight: bold;
}
.replieContainer{
    height: 100px;
    margin-top: 10px;
    .senderReplay{
        height: 90px;
        
    }
}
.label{
     font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold
}
.text{
    font-size: 14px;
}
.chatContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
   height: 300px;
    overflow-y: auto;
 
  }
  
  .chatMessage {
    display: flex;
    word-break: break-word;
  
    height: 100px;
    margin-bottom: 8px;
    margin-top:8px;
    justify-content:center;
   
  
   
    
  }
  
  .left {
    align-items: center;
    justify-content: flex-end;
    
  }
  
  .right {
    justify-content:center ;
    align-items: flex-end;
    
  }
  
  .chatMessage.left p {
    padding: 8px;
    background-color: #d7ffe0;
    border-radius: 8px;
    color:rgb(0, 0, 0);
  }
  
  .chatMessage.right p {
    background-color: #b8dfff;
    padding: 8px;
    border-radius: 8px;
    color:rgb(0, 0, 0)
  }
  
  .chatMessage span {
    margin-left: 0px;
    color: #888888;
    font-size: 10px;
  }
  .modalContainer{
    margin-top: 30px;
    display:flex;
    flex-direction: row;
    column-gap: 10px;
    min-height: 400px;
    
  }
  .infoContainer{
    flex:1;
    display:"flex";
    row-gap: 10px;
   justify-content:flex-start;
   align-items: flex-start;
   height: auto;
   padding: 0 10px;
     
  }
  .infoItem {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  label {
    font-weight: bold;
  }
  
  span {
    margin-left: 10px;
  }
  .rightChatContainer{
    flex:1;
    display:flex;
    flex-direction: column;
    height: auto;
    row-gap: 10;
    
  
    padding: 0px 10px;
  }
  .status-1 {
    color: orange;
  }
  
  .status-2 {
    color: orange;
  }
  
  .status-3 {
    color: green;
  }
  
  .status-4 {
    color: red;
  }
  .cancelButtonContainer {
    position: absolute;
    top: 5px;
    right: 50px;
    z-index: 1;
    cursor: pointer;
  }
  
  .cancelButton {
    color: red;
  }
  