.general-notification-meeting-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    .general-notification-meeting-container-heading {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        span {
            font-size: 1.5rem;
            font-weight: 600;
            color: rgb(147, 147, 147);
        }
    }

    .general-notification-meeting-container-body {
        width: 100%;
        row-gap: 10px;

        .general-notification-meeting-container-form {
            display: flex;
            row-gap: 10px;
            width: 100%;
            height: auto;

            form {
                width: 100%;

                .general-notification-details-container {
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    border-radius: 9px;

                    .general-notification-details-student-container {
                        box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
                        border-radius: 9px;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 20px;


                        height: auto;
                        width: 100%;
                        gap: 12px;


                        .general-notification-details-student-container-subheading {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            padding-left: 1rem;

                        }

                        .general-notification-details-student-container-textfield {

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 12px;

                            .teachers-info-section {

                                width: 98%;
                                display: flex;

                                gap: 25px;
                            }

                        }


                    }
                }
            }
        }
    }
}

.general-notification-meeting-container-table {
    margin-top: 20px;
}