.level-graph{
   
     display: flex;
     flex-direction: column;
     row-gap: 20px;
   
     .container{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
      
        border-radius: 9px;
        height: 40vh;
     
        .heading{
            display: flex;
            width: 98%;
            align-items: center;
            justify-content: space-between;
            .head{
                color: rgb(79, 79, 79);
                font-size: 1.2rem;
            }
            .subhead{
              color:rgb(100, 100, 100);
              font-size: .7rem;
            }

           
        }
        .content{
            width: 98%;
            height: 30vh;
            display: flex;
            justify-content: flex-start;
         
            padding: 0;
          

        }
    }
}