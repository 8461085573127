.assignments-submission{
    width: "100%";
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    .mark-submission{
        display: flex; 
        justify-content: space-between;
        align-items: center;
        p {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: flex-start; 
        }
    }
}

.demo-online-section{
    .mark-submission{
        display: flex; 
        justify-content: space-between;
        align-items: center;
        p {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: flex-start; 
        }
    }
    .meet-link{
        display: flex;
        p {
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.rows {
    display: flex; 
    justify-content: space-between;
    p {
        font-size: 18px;
        font-weight: 600;
        width: 25%;
    }
}

.section {
    display: flex; 
    justify-content: space-between;
}

.no-started{
    font-size: 18px;
    font-weight: 600;
    color: red;
    // color: whitesmoke;
}