$main-color:#1377C0;
.login-container
{
    display: flex;
    width: 100%;
    height: 100vh;
    background-color:#f5f5f502;
    justify-content: center;
    align-items: center;
   
    
    .main-container
    {
         display: flex;
         width: 80%;
         height: 70%;
       
         .left-container
         {
            display: flex;
          
           align-items: center;
            justify-content: center;
            flex: 2;
           
         
            .content-box
            {
                display: flex;
            flex-direction: column;
            justify-content: center; 
            .logo {
                display: flex;
              
                .photo
                {
                  width: 80%;
                
                }
            }
            }
         }
         .right-container
         {
             display: flex;
             flex-direction: column;
             background-color: rgba(255, 255, 255, 0);
             justify-content: center;
             align-items: center;
             box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
             border-radius: 9px;
             margin-left: 5px;
             width:250px;
             flex: 1;
             gap: 10px;
             
             height:450px;
             padding-top: 2rem;
             padding-bottom: 2rem;
            
              .headerImage
         {
           
             width: 400px;
             height:60px;
             margin-bottom: 10px;
             display: flex;
             justify-content: space-between;
          
          
           
             
             align-items:flex-start;
           
             align-items: center;
             justify-content: center;
             img{
               height: 50px;
               height: 50px;
               border-radius: 50%;
                 object-fit: cover;
                 margin-right: 10px;
     
             
             }
            span {
           font-size: 1.5rem;
           margin-left: 10px;
             
            }
         }
             form{
               
                 display: flex;
                 flex-direction: column;
               
                 
                // border-bottom: 1px solid rgb(204, 204, 204);
                 .input-login-container
                 {
                     display: flex;
                  
                     flex-direction: column;
                     margin: 5px 0px;
                     padding: 5px;
                     input {
     
                         background-color:rgb(243, 243, 243);
                         border: 0px solid;
                         height:34px;
                         width:250px;
                         margin-top: 5px;
                         border-radius: 9px;
                         padding: 4px;
                          
                           }
                           ::placeholder { 
                            padding-left: 6px;
                             color: rgb(210, 210, 210);
                             opacity: 1;
                           
                     }
                     input:focus {
                         outline:none;
                     }
                 }
                 .button-container
                 {
                     padding: 5px;
                     margin-top: 4px;
                     button{
                        display: block;
                         width: 254px;
                         height: 40px;
                         border-radius: 9px;
                         font-weight: 500;
                         font-size: 1rem;
                       background-color: $main-color;
                       color: white;
                         
                        border: transparent;
                         cursor: pointer;
                        :hover{
                            background-color: #54cb3c;
                        }
                        
                       }
                     
                       
                   
                    .forgotpassword {
                        margin-top: 3px;
                        display: flex;
                        justify-content: center;
                        
                        span{
                       
                        font-size: .7rem;
                        color: $main-color;
                        cursor: pointer
                    }
                    };
                 }
                 
                 
                
             }
             .changePassword{
                font-size: 0.8rem;
            color:$main-color;
            text-decoration: none;


             }
             .bottom
             {
                margin-top: 5px;
                button{
                    width: 254px;
                         height: 37px;
                         border-radius: 9px;
                         background-color: #54cb3c;
                         cursor: pointer;
                         border: transparent;
                         color: white;
                         font-size: 1rem;
                         text-decoration: none;
                }
             }
         }
    }
}