$main-color:#1377C0;
.subject-test-container
{
      display: flex;
      flex-direction:column;
     
     .teachers{
      flex: 4;
      .teachers-page
      {
        display: flex;
        flex-direction: column;
        gap: 20px;
          
        .teacherForm-page-container{
      
            display: flex;
            flex-direction: column;
            height: auto;
      row-gap: 20px;
            width: 100%;
            .teacherForm-page-container-heading{
                  
                  span{
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: rgb(147, 147, 147);
                  }
            }
            .teachers-info-detail-container{
                  width: 100%;
             
                  display: flex;
                  flex-direction: column;
              gap:12px;
                 border-radius: 9px;
              
           .teachers-info-detail-student-container{
            box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
            border-radius: 9px;
            display: flex;
           flex-direction: column;
           padding-bottom: 20px;
          
          
           height:auto;
           width: 100%;
           gap: 12px;

           
           .teachers-info-detail-student-container-subheading{
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 1rem;
           
           }
           .teachers-info-detail-student-container-textfield{
           
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;
            .teachers-info-section{
                
                  width: 98%;
                  display: flex;
                 
                  gap: 25px;
                  
            }
          
           }
          

           }
      
      }
          
        }

         
      }
     }
}
.subheading{
      font-size: 1rem;
      font-weight: 500;
      color: rgb(56, 56, 56);
}
.buttonSubmit{
      
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 1rem;
      width: 100px;
      height: 50px;
      border-radius: 9px;
      color: white;
      background-color:$main-color;
      text-decoration: none; 
}

.teachers-info-section FormControl{
      width: 250px
}

.grid-value{
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: left; 
}

.subject-values{
      display: flex;
      width: 30%; 
      margin-left: 10px; 
      justify-content: space-evenly;
      align-items: center; 
}

.subject-values h3{
      width: 40%;
}

.subject-values TextField{
      width: 60%;
}
 