$main-color:#1377C0;
.grade-container
{
    display: flex;
 
    .grade{
        flex: 4;
        .grade-page{
            display: flex;
        flex-direction: column;
        gap: 20px;
        // padding: 1rem 0 0 4rem ;
            .grade-detail-heading{
                display: flex;
                flex-direction: column;
                gap: 10px;
                span{
                    font-size: 1.5rem;
                    color: black;
                    font-weight: 500;
                }
                .grade-detail-search{
                    display: flex;
                    gap: 10px;
                    
                    input{
                        flex: 1;
                        width: 140px;
                        height: 50px;
                        padding-left:4px ;
                         border-radius: 9px;
                         border: 1px solid gray;
                    }
                    .grade-detail-search-btn{
                        flex: 1;
                        button{
                            height: 54px;
                            width: 150px;
                            background-color:$main-color;
                            color: white;
                        font-weight: 600;
                        font-size: 1rem;
                            border-radius: 9px;
                            border: none;
                            text-decoration: none;
                        }
                    }
                }
            }
           
        }
    }
}
// .Updatebutton{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 30px;
//     width: 70px;
//     background-color: #009df1;
//     border-radius: 9px;
//     border: none;
//     font-size: .8rem;
//     color: white;
//     font-weight: 600;
//     cursor: pointer;

// }

    button{
    cursor: pointer;
    height: 30px;
    width: 70px;
        border-radius: 9px;
        color: rgb(0, 0, 0);
        font-size:1rem;
        // color:white
      text-decoration: underline;
    //   text-decoration-color: #009df1;
        border: none;
        font-weight: 500;
        background-color: white;
    }
    .modal-subject-container{
        max-height: 300px; /* Set the maximum height for the container */
        overflow-y: auto; 
    flex:1.6;
    display:flex;
    column-gap:20px;
    padding-right: 15px;
 
    align-items:center;
    .modal-subject-container-main{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
}

.modal-subject-container{
    flex:1.6;
    display:flex;
    column-gap:20px;
 
    align-items:center;
    .modal-subject-container-main{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
}


// classes for modals

.form-container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .heading{
        span{
            font-size: 1.5rem;
            font-weight: 600;
            color: rgb(38, 38, 38);
        }
    }
    .student-info{
        display: flex;
        column-gap: 30px;

.student-info-modal{
    display: flex;
     column-gap: 10px;
        label{
 font-size: 1rem;
            font-weight: 600;
            color: rgb(83, 83, 83); 
        }
        span{
              font-size: 1rem;
            font-weight: 600;
            color: rgb(40, 40, 40); 
        }
        
    }
}
    .test_id_select{
       display: grid;
       grid-template-columns: 0.5fr;

        width: 100%;
       
    }
    .modal-subject-main-container{
        display: grid; 
    .modal-subject-container{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-row-gap: 10px;
        grid-column-gap: 20px;
    }
    .form-button-submit{
        display: flex;
      
        justify-content: flex-end;
        button{
            height: 54px;
            width: 150px;
            background-color:$main-color;
            color: white;
        font-weight: 600;
        font-size: 1rem;
            border-radius: 9px;
            border: none;
            text-decoration: none;
        }
    }
}
}

.scroll-container {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .modal-subject-main-container {
    /* Set a fixed width for the scrollbar */
    scrollbar-width: thin; /* "auto" is also a valid value */
  
    /* Set the width and color of the scrollbar thumb (draggable part) */
    scrollbar-color: #888 #f1f1f1;
  
    /* Set other styles for the container if needed */
  }
  
  .modal-subject-main-container::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .modal-subject-main-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
  }
  
  .modal-subject-main-container::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the track */
  }
  
  .modal-subject-main-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
  }
  
  