$main-color:#1377C0;
$sub-heading:rgb(83, 83, 83);
$container-bottom-border:.2px solid rgb(227, 227, 227);;
     
.essential_container{
     
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
      height: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      button{
            display: block;
             width: 254px;
             height: 40px;
             border-radius: 9px;
             font-weight: 500;
             font-size: 1rem;
           background-color: $main-color;
           color: white;
           text-decoration: none;
             
            border: transparent;
             cursor: pointer;
            :hover{
                background-color: #54cb3c;
            }
            
           }

           
.essential_details{
      display: flex;
      flex-direction:row;
      justify-content: space-between;
      column-gap: 10px;
}
.essential_item{
     
      display: flex;
      column-gap: 1rem;
      width: 100%;
      border: 0.5px solid rgb(108, 108, 108);
      padding: .35rem;
      border-radius: 9px;
}
}
    